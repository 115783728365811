@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	font-family: "Roboto","Arial", sans-serif;
}
body {
  background-color: #f8f9fa;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #f8f9fa;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
  background-color: #666;
}

@media (min-width: 992px) {
  .modal-xl {
    max-width: 950px;
  }
}
.edit-users {
  text-align: end;
}
.admin-badge {
  font-size: 0.8em;
}
.table td {
  vertical-align: middle;
}
.searchbar .searchbar-inner {
  width: 80%;
}
.overflow-hidden {
  overflow-x: hidden;
  overflow-y: hidden;
}
.button-overlay {
  position: absolute;
  top: 0%;
  left: 75%;
}
.thumbnail-container {
  position: relative;
}
.product-image {
  object-fit: cover;
  height: 150px;
  cursor: pointer;
  text-align: start;
}
.modal-header .close {
  padding-left: 0;
  padding-right: 0;
}
.tags {
  font-size: 0.7em;
  margin: 0.25em;
}
.page-link {
  color: #009FC3;
}

.top-layer.btn{
  z-index: 100;
  position: relative;
  left: -3em;
}
.profile-name {
  font-size: 0.75em;
  font-weight: 750;
  color: #009FC3;
}
.qty-bar {
  max-width: 4em;
}

.spinner-search {
  position: relative;
  top: 10px;
  right: 13px;
}

.store {
  background: #f8f9fa;
  min-height: 30vh;
}
.app {
  height: 100vh;
}

.spinner-cat {
  text-align: center;
  height: 10vh;
}

#custom input[type=text] {
  background-color: white;
}
#custom .filter-icon {
  transform: translate(0px, 3px);
  padding: 0 0;
}
.filter-menu {
  margin-bottom: 0.5em;
  width: 100%;
  padding: 0.2em 0.7em;
  text-align: left;
  font-size: 0.9em;
  font-weight: bold;
}
.filter-desc {
  font-size: 0.80em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-bottom: 0.5em;
}

.searchbar-box {
  width: 60%;
  max-width: 30em;
  background-color: white;
}
.searchbar {
  width: 100%;
}
.searchbar input {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

#custom input[type="text"], textarea {

  background-color : white; 

}

.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.btn-link {
  color: #009FC3;
}

.wrapper {
  background: #f8f9fa;
}
.nav-link {
  color: black;
}
.menu-list {
  padding: .75rem 1.5rem;
}
.menu-list {
  list-style-type: none;
}
.menu-list li div {
  color: rgba(0,0,0,.5);
  font-weight: 400;
  padding-top: 0.50em;
  padding-bottom: 0.50em;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}
.menu-list li div:hover {
  color: rgba(0,0,0,.7);
  font-weight: 500;
}
.menu-list li, ul{
  margin-bottom: 0;
}
.dropright .dropdown-toggle::after {
  border: none;
}
.dropdown-item {
  padding: 0.50em 1.5em;
}
.navbar-toggler {
  padding: 0.25em 0;
}
.navbar .nav-collapsable
{
  display: contents;
}
.nav-submenu {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.navbar-nav .dropdown-menu {
  padding: 0;
}
.dropright .dropdown-menu {
  width: 45vw;
  overflow-y: auto;
  max-height: 70vh;
  margin-left: 0;
}
.test {
  color: #009FC3;
  color: #0D829D;
  background-color: #9FCF67;
  -webkit-text-decoration-color: #333;
          text-decoration-color: #333;
  background-color: #666;
}

.product-retail {
  color: #999;
  text-align: end;
}

.product-retail-modal {
  color: #999;
  font-size: 0.9em;
}

.product-right{
  position: absolute;
  right: 0;
}

.product-cost {
  text-align: end;
  color: #9FCF67;
  font-weight: bold
}

.product-cost-modal {
  color: #9FCF67;
  font-weight: bold;
  font-size: 1.5em;
}


.footer {
	background: #666;
	min-height: 200px;
	color: #fff;
	padding: 4em 0;
  line-height: 1.7;
}

.foot-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.foot-menu li {
  font-size: .95rem;
  font-weight: 500;
  margin: 0 0 1.5em;
}

.footer-end {
  background: #434343;
  padding: .5em 0;
  color: #f9f9f9;
  margin: 0;
  font-size: .8rem;
}

.columns {
    flex: 1 1;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    min-width: 0;
}

.right-util {
  color: #999;
  margin: 0;
  text-align: right;
  font-size: .8rem;
}

li {
  margin-bottom: 18px;
}
ul.social-icons li {
  display: inline-block;
  margin: 1em 1em .25em;
}
ul {
  padding: 0;
}

p.footer{
  color: #f9f9f9;
  margin: 0;
  padding: 0 1rem;
}

.footer-end p {
  line-height: 2rem;
  padding: 0;
}

p.right-util {
  line-height: 2rem;
  padding: 0
}

a.btn.btn-block.btn-social {
  color: #fff;
}

 a span, button span {
  margin: 0 1em 0;
  display: block;
  text-align: center;
}

.columns-2 {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  grid-column-gap: 4em;
  -webkit-column-gap: 4em;
          column-gap: 4em;
}
.pointer {
  cursor: pointer;
}

a {
  -webkit-transition: all .25s ease .1s;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}
li a:hover, span a:hover, p a:hover {
  color: #9FCF67;
  font-weight: 600;
  text-decoration: none;
}

.green-line {
	width: 75px;
    height: 2px;
    background: #9FCF67;
    margin: 0 0 1.5em;
}

#custom h6 {
	font-size: 1.25rem;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    margin: 0 0 1em;
}

.logo-white {
  width: 65%;
  margin: 0 auto 2em;
}

.column-box {
  margin-bottom: 2em;
}

.social-icons {
  margin: 0 auto;
  list-style-type: none;
  display: inline;
  text-align: center;
}
.social-icons li {
  display: inline-block;
  margin: 0 15px;
  padding: 0;
}

.fab {
  font-size: 2em;
}

.cart-badge {
  margin: 0;
}

.fas {
  font-size: 1.15em;
  padding: 0 1rem 0;
  margin: 0;
  display: inline-flex;
}
.product-button
{
  padding: 0;
}
.badge-main {
  color: #fff;
  background-color: #009fc3;
}
.btn-main {
  color: white;
  background-color: #009fc3;
  border-color: #009fc3;
}
.btn-main:hover {
  color: white;
  -webkit-filter:brightness(85%);
          filter:brightness(85%);
}
.btn-main:focus {
  box-shadow: 0 0 0 0.2rem rgb(0,159,195, 0.5);
}
.btn-custom {
  color: white;
  background-color: transparent;
  border-color: white;
  text-transform: uppercase;
}
.btn-custom:hover {
    color: #fff;
    -webkit-filter:brightness(85%);
            filter:brightness(85%);
}
.btn-custom:focus {
    box-shadow: 0 0 0 0.2rem #f8f9fa4f;
}
.plus {
  font-size: 0.7em;
}
.client-check {
  font-size: 0.9em;
}
.page-link {
  margin-right: 0;
  margin-left: 0;
}
.product-list {
  padding-bottom: 0.6em;
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.product {
  color: #666;
  font-weight: 600;
}
.product:hover {
  color: #9FCF67;
  font-weight: 600;
  text-decoration: none;
}
.description {
  font-size: 0.8em;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0px;
    font-family: "Montserrat","Tahoma",sans-serif;
    line-height: 1.5;
}

.logo-white-box {
  text-align: center;
}
.search-bar-size {
  max-width: 30em;
  width: 100%;
}

.cart-view {
  display: none;
}

.store-window
{
  max-height: 100vh;
}

@media screen and (max-width: 2560px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1850px;
  }
  .filter-large {
    max-width: 20em;
  }

}

@media screen and (max-width: 1920px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1450px;
  }
  .filter-large {
    max-width: unset;
  }
}

@media screen and (max-width: 1680px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1180px;
  }
}

@media screen and (max-width: 1280px) {

}

@media screen and (max-width: 991px) {
  .search-bar-size {
    max-width: 30em;
    width: 100%;
  }
  .navbar .nav-collapsable
  {
    display: block;
    max-width: 30em;
  }
  .searchbar-box {
    max-width: 50em;
  }
  .footer {
    background: #666;
    min-height: 200px;
    color: #fff;
    padding: 4em 0;
    text-align: center;
  }

  .foot-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .green-line {
    width: 75px;
    height: 2px;
    background: #9FCF67;
    margin: 0 auto 1.5em;
  }

  .footer-end, .right-util {
    text-align: center;
  }

  .searchbar-box {
    width: 100%;
  }

  .cart-view {
    display: inline;
  }
  .dropright .dropdown-menu {
    width: 99%;
    max-height: unset;
  }
  .filter {
    display: none;
  }
}

@media screen and (max-width: 736px) {
  
}

@media screen and (max-width: 480px) {
  .columns-2 {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    grid-column-gap: 4em;
    -webkit-column-gap: 4em;
            column-gap: 4em;
  }
}
